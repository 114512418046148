<template>
  <toggle-section
    :is-open="isOpen[getSectionName('businessassociates', index)]"
    :section="getSectionName('businessassociates', index)"
    @toggle="toggleTable"
    chevron-position="right"
  >
    <template #titleSection>
      <div class="flex flex-grow items-center space-x-2">
        <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_ASSOCIATES") }}</lf-h3>
        <lf-pill size="sm" color-index="15">
          {{
            business.AssociateSection?.BusinessAssociates?.BusinessAssociate
              ?.length || 0
          }}
        </lf-pill>
      </div>
    </template>
    <template #content>
      <div class="space-y-5">
        <div
          v-for="(associate, associateIndex) in business.AssociateSection
            ?.BusinessAssociates?.BusinessAssociate"
          :key="associateIndex"
          class="rounded-lg border"
        >
          <div class="flex items-center p-5 border-b justify-between">
            <div class="flex items-center space-x-2">
              <icon-base icon="user" class="-mt-5" />
              <div class="flex flex-col">
                <lf-h3>
                  {{ associate.CompanyName || "-" }}
                </lf-h3>
                <div>
                  {{ associate.Role || "-" }}
                </div>
              </div>
            </div>
          </div>
          <div class="p-5 flex flex-row space-x-2 border-b">
            <icon-base icon="address" class="mt-1" />
            <div class="flex flex-col space-y-0.5">
              <div class="font-semibold text-headline">
                {{
                  associate.Address?.State
                    ? getStateName(associate.Address.State)
                    : "-"
                }}
              </div>
              <div>
                {{ cleanAndFormatAddress(associate.Address) }}
              </div>
            </div>
          </div>
          <toggle-section
            :is-open="
              isOpen[
                getSectionName('businessassociatesids', index, associateIndex)
              ]
            "
            :section="
              getSectionName('businessassociatesids', index, associateIndex)
            "
            @toggle="toggleTable"
            chevron-position="right"
            :key="'business_' + index + '_ids'"
            :has-padding="false"
            :has-border="false"
            class="rounded-lg"
          >
            <template #titleSection>
              <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_IDENTITY") }}</lf-h3>
            </template>
            <template #content>
              <div class="bg-gray-100 p-5">
                <div class="grid grid-cols-2 gap-4">
                  <div class="grid grid-cols-2 grid-rows-4 gap-2 pr-4 border-r">
                    <template
                      v-for="(id, idIndex) in Object.entries(
                        associate.BusinessIds
                      ).slice(0, 4)"
                      :key="idIndex"
                    >
                      <div>{{ camelCaseToWords(id[0]) }}</div>
                      <div class="text-right text-headline">
                        {{ id[1] }}
                      </div>
                    </template>
                  </div>
                  <div class="grid grid-cols-2 grid-rows-4 gap-2">
                    <template
                      v-for="(id, idIndex) in Object.entries(
                        associate.BusinessIds
                      ).slice(4)"
                      :key="idIndex"
                    >
                      <div>{{ camelCaseToWords(id[0]) }}</div>
                      <div class="text-right text-headline">
                        {{ id[1] }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </toggle-section>
        </div>
      </div>
    </template>
  </toggle-section>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import ToggleSection from "@/components/ToggleSection.vue";
import type { Business } from "@/models/commercialData/lexisnexis/KybReport";
import {
  getSectionName,
  getStateName,
  cleanAndFormatAddress
} from "@/helpers/lexisnexis";
import { camelCaseToWords } from "@/helpers/formatting";

defineProps({
  business: {
    type: Object as PropType<Business>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Object,
    required: true
  }
});

const emit = defineEmits<{
  (eventName: "toggle", key: string): void;
}>();
const toggleTable = (section: string) => {
  emit("toggle", section);
};
</script>
